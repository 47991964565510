import axios from 'axios';
import { apiUrl, timeout, tokenName } from 'constants/defaultValues';
import { getCookie, getCurrentUser } from 'helpers/utils';

const { CancelToken } = axios;

const CreateDefaultHeaders = (token, param) => {
  const payload = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  };
  return Object.assign(payload, param);
};

export default function CreateBaseRequest(cancelFuncion, param) {
  const user = getCurrentUser();
  let token = user && user.token ? user.token : getCookie(tokenName);
  if (param && param.token) {
    token = param.token;
  }
  // console.log('[TOKEN]: ', token);
  return axios.create({
    baseURL: apiUrl, // process.env.REACT_APP_BASE_URL_API
    timeout: param && param.timeout ? param.timeout : timeout,
    // withCredentials: true,
    headers: CreateDefaultHeaders(token, param),
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      if (typeof cancelFuncion === 'function') cancelFuncion(c);
    }),
  });
}

export const Get = (url, cancelFuncion, param) => {
  return CreateBaseRequest(cancelFuncion, param).get(url);
};

export const Post = (url, postData, cancelFuncion, param) => {
  return CreateBaseRequest(cancelFuncion, param).post(url, postData);
};

export const Put = (url, putData, cancelFunction, param) => {
  return CreateBaseRequest(cancelFunction, param).put(url, putData);
};

export const Delete = (url, cancelFunction) => {
  return CreateBaseRequest(cancelFunction).delete(url);
};
