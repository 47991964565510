const VERSAO = 'v1.0';
const ENDPOINTS = {
  exames: {
    obterExames: `/api/${VERSAO}/exame/listar`,
    obterPorToken: `/api/${VERSAO}/exame/obter/token`,
    compartilhar: `/api/${VERSAO}/exame/compartilhar`,
    link: `/api/${VERSAO}/exame/link`,
    pdfAnatomia: `/api/${VERSAO}/exame/pdf/anatomia`,
    pdfRadiologia: `/api/${VERSAO}/exame/pdf/imagem`,
    gravarHistorico: `/api/${VERSAO}/exame/historico`,
    listarCombo: `/api/${VERSAO}/exame/listar/combo`,
  },
  login: {
    autenticarMedico: `/api/${VERSAO}/login/medico`,
    autenticarPaciente: `/api/${VERSAO}/login/paciente`,
    relembrarSenha: `/api/${VERSAO}/login/relembrar`,
    redefinirSenha: `api/${VERSAO}/login/redefinir`,
    forcaSenha: `api/${VERSAO}/login/forcadasenha`,
    token: `api/${VERSAO}/token/refresh`,
    mfaReenviar: `api/${VERSAO}/login/mfa/reenviar`,
    mfaValidar: `api/${VERSAO}/login/mfa/validar`,
    trocarsenha: `api/${VERSAO}/login/trocarsenha`,
    cadastro: `api/${VERSAO}/login/email/cadastro`,
    confirmacao: `api/${VERSAO}/login/email/confirmacao`,
  },
  medico: {
    validarMedico: `/api/${VERSAO}/medico/validar`,
    solicitarAcesso: `/api/${VERSAO}/medico/acesso/solicitar`,
    pesquisarMedico: `/api/${VERSAO}/medico/pesquisar`,
  },
  paciente: {
    validarPaciente: `/api/${VERSAO}/paciente/validar`,
    pesquisarPaciente: `/api/${VERSAO}/paciente/pesquisar`,
  },
  configuracao: {
    listarConfiguracaoInicial: `/api/${VERSAO}/configuracao/inicial`,
    listarConfiguracaoEmail: `/api/${VERSAO}/configuracao/email/listar`,
  },
  token: {
    token: `/api/${VERSAO}/token`,
    refreshToken: `/api/${VERSAO}/token/refresh`,
  },
  viewers: {
    vivaceConnectUrl: `/api/${VERSAO}/vivaceconnect/url`,
    vivaceListarDownload: `/api/${VERSAO}/vivaceconnect/estudos/listar`,
  },
  procedimento: {
    listarCombo: `/api/${VERSAO}/procedimento/listar/combo`,
  },
};

export default ENDPOINTS;
